'use client';

import * as Styled from './styled';
import { useRouter } from 'next/navigation';

export interface ButtonProps {
  text: string;
  page: string;
  id?: number;
  slug?: string;
}

export const Button = ({ text, page, id, slug }: ButtonProps) => {
  const router = useRouter();
  const uri =
    (id === null || id === undefined) && (slug === null || slug === undefined)
      ? page
      : `${page}/${id != null || id != undefined ? id : slug}`;

  return (
    <Styled.Container
      type="button"
      onClick={() =>
        router.push(uri, {
          scroll: false,
        })
      }
    >
      {text}
    </Styled.Container>
  );
};
