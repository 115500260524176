import * as Styled from './styled';
import Heading from '../Heading';
import { Tags } from '../Tags';
import { Button } from '../Button/index';
import { PostedDate } from '../PostedDate';

export interface PostProps {
  id: number;
  imageSrc: string;
  imageAlt: string;
  title: string;
  tags: string[];
  postedDate: string;
}

export const PostDetail = (props: PostProps) => {
  return (
    <Styled.PostSummary>
      <img src={props.imageSrc} alt={props.imageAlt} />
      <Heading title={props.title} />
      <Styled.Information>
        <Tags tags={props.tags} />
        <PostedDate key={props.postedDate} postedDate={props.postedDate} />
      </Styled.Information>
      <Button text="Read" key={props.id} page="/post" id={props.id} />
    </Styled.PostSummary>
  );
};
